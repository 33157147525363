<template>
  <b-modal
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    :title="'Files of ' + clientName"
    size="xl"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <PersonalInformation
      :clientId="clientAccId"
      :showFiles="true"
      :gridName="gridName"
      :fromModal="true"
    />
  </b-modal>
</template>
<script>
import PersonalInformation from "@/views/commons/components/ncr/components/report/components/client-info/PersonalInformation.vue";

export default {
  props: {
    clientAccId: String,
    clientName: String,
  },
  components: {
    PersonalInformation,
  },
  data() {
    return {
      gridName: "showFiles"
    };
  },
  mounted() {
    this.toggleModal("modalName");
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
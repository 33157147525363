<template>
  <div>
    <b-nav card-header pills class="m-0 mt-1">
      <b-nav-item
        :to="{ name: inCorresponcendeTabName }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >In Correspondece</b-nav-item
      >
      <b-nav-item
        :to="{ name: sentTabName }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >Sent</b-nav-item
      >
    </b-nav>
    <b-card class="border-top-info border-3 border-table-radius px-0">
      <LetterRoundTable />
    </b-card>
  </div>
</template>
  <script>
import LetterRoundTable from "@/views/commons/components/letters-v2/components/LettersDisputeTable.vue";

export default {
  components: {
    LetterRoundTable,
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    inCorresponcendeTabName() {
      const { routeCompletedInCorrespondence } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return routeCompletedInCorrespondence;
    },
    sentTabName() {
      const { routeCompletedSent } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return routeCompletedSent;
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      v-model="modalCenter"
      centered
      modal
      size="lg"
      no-close-on-backdrop
      title="PROCESS"
      @hidden="hideModal"
    >
      <div class="mb-1 mx-1">
        <b-row>
          <b-col cols="12" md="6">
            <span class="title-tag">ACCOUNT</span>
            <div class="border border-primary rounded">
              <p
                class="text-primary d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ dataClient.client_account || "-" }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <span class="title-tag">CLIENT</span>
            <div class="border border-primary rounded">
              <p
                class="text-primary d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ dataClient.client_name || "-" }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-container>
        <b-row>
          <b-col cols="2" class="ml-1 bg-primary rounded-top paddingTop">
            <h6 class="text-white text-center">OBSERVATION</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-observer ref="refFormObserver">
              <validation-provider
                name="Creditor Name"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="observation"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                  :state="errors[0] ? false : null"
                ></b-form-textarea>
              </validation-provider>
            </validation-observer>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button @click="hideModal">Cancel</b-button>
        <b-button variant="primary" @click="processLetter">
          {{ inToSent ? "Process" : "Save" }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import LettersService from "@/views/commons/components/letters/service/letters.service.js";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
export default {
  components: {
    ButtonSave,
    ButtonCancel,
  },
  props: {
    dataClient: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: true,
      observation: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    inToSent() {
      return [2, 10].includes(this.dataClient?.state);
    },
  },
  methods: {
    ...mapActions({
      A_COUNTER_PENDING_ROUND_LETTERS:
        "SpecialistsDigitalRoundLettersStore/A_COUNTER_PENDING_ROUND_LETTERS",
      A_COUNTER_DEPT_RETURNED_LETTERS:
        "SpecialistsDigitalRoundLettersStore/A_COUNTER_DEPT_RETURNED_LETTERS",
    }),
    async processLetter() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const { data } = await LettersService.processRoundLetters({
          id: this.dataClient.id,
          user: this.currentUser.user_id,
          type: this.inToSent ? 5 : 2,
          // type: this.this.type,
          obs: this.observation,
          ittra: this.dataClient.sumtr ? this.dataClient.sumtr : 0,
          itexp: this.dataClient.sumex ? this.dataClient.sumex : 0,
          itequ: this.dataClient.sumeq ? this.dataClient.sumeq : 0,
          request_work_plan_id: this.dataClient.request_work_plan_id,
        });
        if (!data.success) {
          this.removePreloader();
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "XIcon",
            data.message
          );
          return;
        }

        // update table dispute letters counter
        await this.A_COUNTER_PENDING_ROUND_LETTERS();
        await this.A_COUNTER_DEPT_RETURNED_LETTERS(this.moduleId);
        this.$emit("updateTable");
        this.hideModal();
        this.removePreloader();
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "The letter has been process. " + data.message
        );
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    hideModal() {
      this.modalCenter = false;
      this.$emit("hideModal");
    },
  },
};
</script>
<style scoped>
.paddingTop {
  padding-top: 12px;
}
</style>

import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";

const filters = [
    {
        key: "from",
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        nameFilter: 'from'
    },
    {
        key: "to",
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        nameFilter: 'to'
    },
    {
      key: "type",
      type: "select",
      margin: true,
      showLabel: true,
      label: "Type",
      model: null,
      options: [
        { value: 0, label: 'ALL' },
        { value: 1, label: 'REGULAR' },
        { value: 2, label: 'CERTIFIED' },
      ],
      reduce: "value",
      selectText: "label",
      cols: 12
    },
    {
      key: "created_by",
      type: "select",
      margin: true,
      showLabel: true,
      label: "Created by",
      model: null,
      options: [],
      reduce: "id",
      selectText: "text",
      cols: 12,
      visible: true,
  },
];


const processFilters = async () => {
  const { users } = await RequestWorkPlanService.getCreditAnalysts();
  filters[3].options = users;
};

processFilters();

export default filters;

<template>
  <div>
    <filter-slot
      :filter="filterSlot.filters"
      :filterSearch="filterSlot.filterSearch"
      :paginate="filterSlot.paginate"
      :totalRows="filterSlot.totalRows"
      :startRow="filterSlot.startRow"
      :endRow="filterSlot.endRow"
      :toPage="filterSlot.toPage"
      @reset-all-filters="resetAllFilters"
      @reload="$refs['letterDirectTable'].refresh()"
    >
      <b-table
        ref="letterDirectTable"
        slot="table"
        class="blue-scrollbar"
        :items="myProvider"
        :fields="visibleFields"
        :busy.sync="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :per-page="filterSlot.paginate.perPage"
        :current-page="filterSlot.paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(client)="data">
          <span class="d-flex align-items-center" style="gap: 5px">
            <div>
              <router-link
                target="_blank"
                :to="{
                  name: clientDashboardRouteName,
                  params: {
                    idClient: `${data.item.client_account_id}`,
                  },
                }"
              >
                {{ data.item.client_name }}
              </router-link>
            </div>
          </span>
          <status-account :account="data.item" />
          <span class="d-block" style="margin-left: 18px">
            {{ data.item.client_account }}
          </span>
        </template>

        <template #cell(type)="data">
          <div>
            <b-badge :variant="badgeVariant(data.item.type_send)">
              {{ data.item.type_send }}
            </b-badge>
          </div>
        </template>

        <template #cell(round)="data">
          <div>
            <b-badge
              variant="light-primary"
              @click="openModalAddRound(data.item)"
              class="cursor-pointer"
            >
              {{ data.item.round_title.split(" ")[0] }}
              ({{ data.item.sumeq + data.item.sumex + data.item.sumtr }})
            </b-badge>
          </div>
        </template>

        <template #cell(created_by)="data">
          <div>
            {{ data.item.created_by }}
            <br />
            {{ data.item.created_at | myGlobalDay }}
          </div>
        </template>

        <template #cell(procesed_by)="data">
          <div>
            {{ data.item.procesed_by }}
            <br />
            {{ data.item.date_specialist | myGlobalDay }}
          </div>
        </template>

        <template #cell(deleted_by)="data">
          <div>
            {{ data.item.deleted_by }}
            <br />
            {{ data.item.deleted_at | myGlobalDay }}
          </div>
        </template>

        <template #cell(tracking)="data">
          <div>
            <feather-icon
              class="text-info cursor-pointer"
              v-b-tooltip.hover.right="'View Tracking'"
              size="16"
              icon="ListIcon"
              @click="openModalTracking(data.item)"
            />
          </div>
        </template>

        <template #cell(actions)="data">
          <feather-icon
            @click="openFilesModal(data.item)"
            class="text=primary cursor-pointer mr-1"
            icon="UploadIcon"
            v-b-tooltip.hover.top="'Upload missing files'"
            size="16"
            v-if="data.item.total_id == 0 || data.item.total_ub == 0"
          />
          <feather-icon
            @click="openModalProcess(data.item)"
            class="text-primary cursor-pointer mr-1"
            icon="SendIcon"
            v-b-tooltip.hover.top="'Send to Correspondence'"
            size="16"
            v-else
          />
          <feather-icon
            @click="deleteRoundLetter(data.item.id)"
            class="text-danger cursor-pointer mr-1"
            icon="TrashIcon"
            v-b-tooltip.hover.top="'Delete Round'"
            size="16"
          />
        </template>
      </b-table>
    </filter-slot>

    <modal-tracking-letter-round
      v-if="modalTrackingState"
      :dataClient="dataClient"
      @hideModal="modalTrackingState = false"
    />

    <modal-process
      v-if="modalProcessState"
      :dataClient="dataProcess"
      @updateTable="$refs['letterDirectTable'].refresh()"
      @hideModal="modalProcessState = false"
    />

    <add-round-modal
      v-if="modalAddRoundLetterState"
      :data-round="itemClient"
      :validate-modal="validateModal"
      :show-all-letters="false"
      @close="modalAddRoundLetterState = false"
    />

    <files-accounts-modal
      v-if="showFilesModal"
      :clientAccId="clientAccId"
      :clientName="clientName"
      @close="showFilesModal = false"
    />
  </div>
</template>
<script>
// DATA
import Fields from "@/views/commons/components/letters-v2/data/fields";
import Filters from "@/views/commons/components/letters-v2/data/filters";
import FilterSlot from "@/views/specialist-digital/views/updates/slot/FilterSlot.vue";

// COMPONENTS
import ModalTrackingLetterRound from "@/views/specialists/sub-modules/analyst-department/views/letter-round/views/modals/ModalTrackingLetterRound.vue";
import ModalProcess from "@/views/specialists/sub-modules/analyst-department/views/letter-round/views/modals/ModalProcessRoundLetter.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import FilesAccountsModal from "@/views/commons/components/letters-v2/modals/FilesAccountsModal.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

// SERVICE
import ClientOptions from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
import LettersRoundsService from "@/views/specialists/sub-modules/analyst-department/views/letter-round/services/letters-rounds.service.js";

// STORE
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    FilterSlot,
    ModalTrackingLetterRound,
    ModalProcess,
    AddRoundModal,
    FilesAccountsModal,
    StatusAccount,
  },
  data() {
    return {
      Fields,
      filterSlot: {
        filters: Filters,
        filterSearch: {
          type: "input",
          inputType: "text",
          placeholder: "Client...",
          model: "",
        },
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        //rows
        totalRows: 0,
        startRow: 0,
        endRow: 0,
        toPage: 0,
      },
      isBusy: false,
      modalTrackingState: false,
      dataClient: [],
      modalProcessState: false,
      dataProcess: [],
      modalAddRoundLetterState: false,
      showFilesModal: false,
      clientAccId: null,
      clientName: null,
    };
  },
  props: {
    tabSelected: String,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
      updateLetterTable:
        "SpecialistsDigitalRoundLettersStore/G_UPDATE_LETTER_TABLE",
    }),

    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },

    isPendingSubTab() {
      const { routeToSendPending } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return routeToSendPending == this.$route.name;
    },

    isInQueueSubTab() {
      const { routeToSendInQueue } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return routeToSendInQueue == this.$route.name;
    },

    isReturnedSubTab() {
      const { routeToSendReturned } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return routeToSendReturned == this.$route.name;
    },

    isCompletedTab() {
      return this.isInCorrespondenceSubTab || this.isSentSubTab;
    },
    isDeletedTab() {
      const { routeDeleted } =
        this.moduleId != 5
          ? this.$route.matched[1].meta
          : this.$route.matched[3].meta;
      return routeDeleted == this.$route.name;
    },

    isExpiredTab() {
      const { routeExpired } =
        this.moduleId != 5
          ? this.$route.matched[1].meta
          : this.$route.matched[3].meta;
      return routeExpired == this.$route.name;
    },

    isInCorrespondenceSubTab() {
      const { routeCompletedInCorrespondence } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return routeCompletedInCorrespondence == this.$route.name;
    },

    isSentSubTab() {
      const { routeCompletedSent } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return routeCompletedSent == this.$route.name;
    },

    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
    visibleFields() {
      return this.Fields.filter((field) => field.visible);
    },

    setState() {
      if (this.isPendingSubTab) return 2;
      if (this.isInQueueSubTab) return 1;
      if (this.isInCorrespondenceSubTab) return 5;
      if (this.isSentSubTab) return 7;
      if (this.isReturnedSubTab) return 10;
      if (this.isExpiredTab) return 11;
      return null;
    },

    validateModal() {
      return 3;
    },
  },

  async created() {
    await this.start();
  },

  methods: {
    ...mapActions({
      A_UPDATE_TABLE:
        "SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE",
    }),
    openFilesModal(item) {
      this.clientAccId = item.client_account_id;
      this.clientName = item.client_name;
      this.showFilesModal = true;
    },
    async start() {
      // FIELDS
      this.filterColumn("deleted_by", this.isDeletedTab);
      this.filterColumn("created_by", !this.isDeletedTab);
      this.filterColumn(
        "actions",
        this.isPendingSubTab || this.isReturnedSubTab
      );
      this.filterColumn("tracking", true);
      this.filterColumn("procesed_by", this.isInCorrespondenceSubTab);
      this.filterColumn("created_by", !this.isInCorrespondenceSubTab);
    },

    getFilterByKey(key) {
      const filter = this.filterSlot.filters.find(
        (filter) => filter.key === `${key}`
      );

      return filter;
    },

    async myProvider(ctx) {
      try {
        let params = {
          nameText: this.filterSlot.filterSearch.model,
          dateFrom: this.getFilterByKey("from").model,
          dateTo: this.getFilterByKey("to").model,
          module: this.moduleId,
          specialist: this.getFilterByKey("created_by").model,
          state: this.setState,
          typeSend: this.getFilterByKey("type").model,
          isDeleted: this.isDeletedTab,
          isConnection: this.moduleId == 20,
          ...ctx,
        };
        const data = await LettersRoundsService.search(params);
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        this.filterSlot.totalRows = data.total;
        this.filterSlot.toPage = data.to;
        this.filterSlot.startRow = data.from;
        this.filterSlot.endRow = data.to;
        return data.data || [];
      } catch (error) {
        console.error("Something went wrong with the provider", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    resetAllFilters() {
      this.filterSlot.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterSlot.filterSearch.model = null;
      this.$refs.letterDirectTable.refresh();
    },

    async deleteRoundLetter(id) {
      try {
        const result = await this.showConfirmSwal();
        if (!result.isConfirmed) return;
        const params = { id: id, user_id: this.currentUser.user_id };
        const response = await ClientOptions.deleteRoundLetter(params);
        if (response.status === 200) {
          this.showSuccessSwal();
          this.$refs.letterDirectTable.refresh();
        }
      } catch (error) {
        console.error("Error trying to delete a round letter", error);
      }
    },

    openModalTracking(data) {
      this.dataClient = data;
      this.modalTrackingState = true;
    },

    openModalProcess(data) {
      this.dataProcess = data;
      this.modalProcessState = true;
    },

    badgeVariant(value) {
      if (value == "CERTIFIED") {
        return "light-warning";
      } else {
        return "light-success";
      }
    },

    filterColumn(key, bool) {
      let field = this.Fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    openModalAddRound(data) {
      this.itemClient = {
        id: data.ncr_letters_id,
        idNrl: data.id,
        idAccount: data.client_account_id,
        account: data.client_account,
        clientName: data.client_name,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          itin: data.itin,
          other: data.other,
          origin_country: data.origin_country,
        },
        onlyRound: true,
        isDirect: { id: data.id, title: data.round_title, format: data.format },
      };
      this.modalAddRoundLetterState = true;
    },
  },

  watch: {
    updateLetterTable(newState) {
      if (newState) {
        this.$refs.letterDirectTable.refresh();
        this.A_UPDATE_TABLE(false);
      }
    },
  },
};
</script>

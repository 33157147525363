import { amgApi } from "@/service/axios";

class LettersService {
  async getlettersstatus() {
    const data = await amgApi.post("commons/boost-credit/get-letters-status")
    return data.data
  }

  async getRoundLetterProgram(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/get-round-letter-program",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getRoundLetterProgram");
    }
  }
  async getUsersAllPrograms(params) {
    try {
      const data = await amgApi.post("commons/users-all-programs", params);
      return data.data;
    } catch (error) {
      console.log("Error getUsersAllPrograms");
    }
  }
  async getTrackingsLetters(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/get-trackings-letters",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getTrackingsLetters");
    }
  }
  async insertProcessLetters(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/insert-process-letters",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error insertProcessLetters");
    }
  }
  async getAccountCnPi(params) {
    try {
      const data = await amgApi.post("debt-solution/get-account-cn-pi", params);
      return data.data;
    } catch (error) {
      console.log("Error  getAccountCnPi");
    }
  }
  async getAccountCnIn(params) {
    try {
      const data = await amgApi.post("debt-solution/get-account-cn-in", params);
      return data.data;
    } catch (error) {
      console.log("Error  getAccountCnIn");
    }
  }
  async getAccountCnPr(params) {
    try {
      const data = await amgApi.post("debt-solution/get-account-cn-pr", params);
      return data.data;
    } catch (error) {
      console.log("Error  getAccountCnPr");
    }
  }
  async getAccountCnAc(params) {
    try {
      const data = await amgApi.post("debt-solution/get-account-cn-ac", params);
      return data.data;
    } catch (error) {
      console.log("Error  getAccountCnAc");
    }
  }
  async getPDFRound(params) {
    try {
      const data = await amgApi.post("debt-solution/get-pdf-round", params);
      return data.data;
    } catch (error) {
      console.log("Error  getPDFRound");
    }
  }
  async processRoundLetters(body) {
    try {
      const data = await amgApi.post("/dispute/process-round-letters", body);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new LettersService();
